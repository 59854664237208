import $ from 'jquery'
import { TweenMax, Power2, TimelineLite, TweenLite } from 'gsap'
import Cookies from 'js-cookie'

export default class AgeGate {
  constructor () {
    this.cookieName = 'lezajsk'
  }

  init ($app) {
    this.app = $app
    var _c = Cookies.get(this.cookieName);
    var gatePass = Cookies.get('gate_pass');

    if (_c) {
      $('body').addClass('age-confimed');
      this.remove();

      if (gatePass) {
          if (Cookies.get('cookieAgreement') == 'accept') {
            dataLayer.push({
              'event': 'gate_pass'
            });
          }
          Cookies.remove('gate_pass', { path: '/' });
      }

      // this.app.init()
    } else {
      if (Cookies.get('cookieAgreement') == 'accept') {
        dataLayer.push({
          'event': 'gate'
        });
      }
      this.initGate();
    }
  }

  ageGateSuccess (e) {
      Cookies.set('gate_pass', 'true', { expires: 1, path: '/' })
      setTimeout(function () {
          location.reload();
      },1000)
    // this.app.init()
    // $('body').addClass('age-confimed')
    // TweenMax.to('#age-gate-content-title', 0.4, { autoAlpha: 0, y:-30 })
    // TweenMax.to('#age-gate-content-desc', 0.4, { autoAlpha: 0, y: 50 })
    // TweenMax.to('.GateForm', 0.4, { autoAlpha: 0, y: 50 })
    // TweenMax.to('.age-gate', 0.9, { autoAlpha: 0, onComplete: this.ageGateHideComplete.bind(this) })
  }

  ageGateHideComplete (e) {
    this.remove();
  }

  remove () {
    $('.age-gate').remove();
  }

  initGate () {
    $('.age-gate').show();
    this._elem = $('.age-gate')[0];
    this.initContent();

    /*
    $('.popup-cookies-buttons .button-dec')[0].addEventListener('click', this.onCookiesDec.bind(this))
    $('.popup-cookies-buttons .button-agree')[0].addEventListener('click', this.onCookiesAccept.bind(this))
    if (Cookies.get(this.tatraPPCookie)) {
      TweenMax.to('.cookies-overlay', 0.4, { autoAlpha: 0 })
    } else {
      TweenMax.to('.cookies-overlay', 0.4, { autoAlpha: 1 })
    }
    */

    // TweenMax.from('#age-gate-content-title', 1.4, { alpha: 0 })
    // TweenMax.from('#age-gate-content-desc', 1.4, { alpha: 0, y: 10 })
    // TweenMax.from('.GateForm', 1.4, { alpha: 0, y: 10, delay: 0.2 })
    // TweenMax.to('.age-gate', 1, { alpha: 1 })
  }

  /*
  onCookiesAccept () {
    Cookies.set(this.cookieName, 'true', { expires: 1, path: '/' })
    TweenMax.to('.cookies-overlay', 0.4, { autoAlpha: 0 })
  }
  onCookiesDec () {
    TweenMax.to('.cookies-overlay', 0.4, { autoAlpha: 0 })
  }
  */

  initContent () {
    this.infoTxt = $(this._elem).find('.info-txt')[0];
    this.cnt = $(this._elem).find('.age-gate-content')[0];
    this.form = $(this._elem).find('.GateForm')[0];
    this.days = $(this._elem).find('#days')[0];
    this.month = $(this._elem).find('#month')[0];
    this.year = $(this._elem).find('#year')[0];
    this.errorTxt = $(this._elem).find('.Erorrs')[0];
    this._btn = $(this._elem).find('button')[0];

    this.days.mh = 2;
    this.days.ni = this.month;
    this.days.pf = null;

    this.month.mh = 2;
    this.month.ni = this.year;
    this.month.pf = this.days;

    this.year.mh = 4;
    this.year.ni = null;
    this.year.pf = this.month;

    this.days.addEventListener('focus', this.onFocus.bind(this));
    this.month.addEventListener('focus', this.onFocus.bind(this));
    this.year.addEventListener('focus', this.onFocus.bind(this));
    this.days.addEventListener('keyup', this.keyUpHandler.bind(this));
    this.month.addEventListener('keyup', this.keyUpHandler.bind(this));
    this.year.addEventListener('keyup', this.keyUpHandler.bind(this));
    this._btn.addEventListener('click', this.onSubmitt.bind(this));

    $('#form-agegate').submit(this.onSubmitt.bind(this))
  }

  onSubmitt (e) {
    e.preventDefault()
    this.error = null
    if (this.isValid(parseInt(this.days.value), parseInt(this.month.value), parseInt(this.year.value))) {
      var age = this.getAge(parseInt(this.days.value), parseInt(this.month.value), parseInt(this.year.value))
      if ((age >= 18) && (age < 120)) {
        Cookies.set(this.cookieName, 'true', { expires: 1, path: '/' })
        this.ageGateSuccess()
      } else {
        if (age < 18 && age > 2) {
          this.error = 'Strona dostępna jest tylko dla użytkowników pełnoletnich.'
        } else {
          this.error = 'Podałeś błędną datę urodzenia. Podaj właściwą datę.'
        }
      }
    }
    if (this.error) {
      this.DOMaddClass('error', this.infoTxt)
      this.infoTxt.innerHTML = this.error
    }
  }

  keyUpHandler ($e) {
    var _event = $e || window.event
    var _key = _event.keyCode || _event.which
    var _v = this.validateNumbers(_key)
    if (!this.validateNumbers(_key)) {
      $e.returnValue = false
      if ($e.preventDefault) {
        $e.preventDefault()
      }
    }
    var _field = _event.target
    var _value = _field.value

    // backspace
    if (_key == 8 && _field.pf && _value.length == 0) {
      _field.pf.focus()
    }

    if (_value.length == _field.mh) {
      if (_field.ni) {
        _field.ni.focus()
      }
    }
    if (_value.length > _field.mh) {
      _field.value = _value.substring(0, _field.mh)
    }
    this.checkFields()
  }

  checkFields () {
    this.validated = [0, 0, 0]
    this.messages = []

    var dayValue = this.days.value;

    while(dayValue.charAt(0) === '0')
    {
      dayValue = dayValue.substring(1);
    }

    if (dayValue.length) {
      if (isNaN(parseInt(dayValue))) {
        this.DOMaddClass('NotAccepted', this.days)
        this.days.value = 31;
      } else {
        this.DOMremoveClass('NotAccepted', this.days)
        if (parseInt(dayValue) > 0 && parseInt(dayValue) < 32) {
          this.DOMaddClass('Accepted', this.days)
          this.validated[0] = parseInt(dayValue)
        } else {
          this.DOMremoveClass('Accepted', this.days)
          if (dayValue.length) {
            this.DOMaddClass('NotAccepted', this.days)
            this.days.value = 31
          }
        }
      }
    }

    if (this.month.value.length) {
      var monthValue = this.month.value;

      while(monthValue.charAt(0) === '0')
      {
        monthValue = monthValue.substring(1);
      }

      if (isNaN(parseInt(monthValue))) {
        this.DOMaddClass('NotAccepted', this.month)
      } else {
        this.DOMremoveClass('NotAccepted', this.month)
        if (parseInt(monthValue) > 0 && parseInt(monthValue) < 13) {
          this.DOMaddClass('Accepted', this.month)
          this.validated[1] = parseInt(monthValue)
          var _months = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
          if (parseInt(monthValue) >= _months[parseInt(monthValue) - 1]) {
            this.DOMaddClass('NotAccepted', this.days)
            this.validated[0] = 0
            this.days.value = _months[parseInt(monthValue) - 1]
          }
        } else {
          this.DOMremoveClass('Accepted', this.month)
          if (this.month.value.length) {
            this.DOMaddClass('NotAccepted', this.month)
            this.month.value = 12
          }
        }
      }
    }

    if (this.year.value.length == 4) {
      var d = new Date()
      var _nowYear = d.getFullYear()
      if (isNaN(parseInt(this.year.value))) {
        this.DOMaddClass('NotAccepted', this.year)
      } else {
        this.DOMremoveClass('NotAccepted', this.year)
        if (_nowYear - parseInt(this.year.value) < 120 && _nowYear - parseInt(this.year.value) > 17) {
          this.DOMaddClass('Accepted', this.year)
          this.validated[2] = parseInt(this.year.value)
        } else {
          this.DOMaddClass('NotAccepted', this.year)
        }
      }
    }

    if (this.validated[0] && this.validated[1] && this.validated[2]) {
      var age = this.getAge(parseInt(this.days.value), parseInt(this.month.value), parseInt(this.year.value))
      if ((age < 18) || (age > 120)) {
        this.DOMaddClass('NotAccepted', this.year)
        this.validated[2] = 0
      } else {
        this.DOMremoveClass('NotAccepted', this.year)
      }
    }
  }

  validateNumbers ($key) {
    if ($key >= 96 && $key <= 105) {
      return true
    }
    if ($key >= 48 && $key <= 57) {
      return true
    }
    return false
  }

  onFocus (e) {
    this.DOMremoveClass('Error', this.cnt)
    /*console.log('onFocus')
    console.log(e)
    console.log(e.target)*/
    if(e.target.getAttribute('name') == 'month'){
      //console.log(e)
    }
  }

  getAge (birthDay, birthMonth, birthYear) {
    var today = new Date()
    var birthDate = new Date(birthYear, birthMonth - 1, birthDay)

    var age = today.getFullYear() - birthDate.getFullYear()
    var m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }

    return age
  }

  daysInMonth (m, y) { // m is 0 indexed: 0-11
    switch (m) {
      case 2 :
        return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28
      case 9 :
      case 4 :
      case 6 :
      case 11 :
        return 30
      default :
        return 31
    }
  }

  isValid (d, m, y) {
    if (y.length > 4 || y.length < 4) {
      this.error = 'Podałeś błędną datę urodzenia. Podaj właściwą datę.'
      return false
    }
    var _t = m >= 1 && m <= 12 && d > 0 && d <= this.daysInMonth(m, y)
    if (!_t) {
      this.error = 'Podałeś błędną datę urodzenia. Podaj właściwą datę.'
    }
    return _t
  }

  DOMaddClass (cls, node) {
    if (node.classList) {
      node.classList.add(cls)
    } else {
      var cur = ' ' + (node.getAttribute('class') || '') + ' '
      if (cur.indexOf(' ' + cls + ' ') < 0) {
        node.setAttribute('class', (cur + cls).trim())
      }
    }
  };

  DOMremoveClass (cls, node) {
    if (node.classList) {
      node.classList.remove(cls)
    } else {
      var cur = ' ' + (node.getAttribute('class') || '') + ' '
      var tar = ' ' + cls + ' '
      while (cur.indexOf(tar) >= 0) {
        cur = cur.replace(tar, ' ')
      }
      node.setAttribute('class', cur.trim())
    }
  };

  DOMcreate (type, c, id, attrs) {
    type = typeof type !== 'undefined' ? type : 'div'

    if (type.substr(0, 3) == 'svg') {
      var e = document.createElementNS('http://www.w3.org/2000/svg', type.split(':').length > 1 ? type.split(':')[1] : type.split(':')[0])
    } else {
      var e = document.createElement(type)
    }

    var _classes = []
    if (id) {
      if (Object.prototype.toString.call(id) !== '[object Array]') {
        id = [id]
      }
      for (var i = id.length - 1; i >= 0; i--) {
        switch (id[i].charAt(0)) {
          case '.':
            _classes.push(id[i].substring(1))
            break
          case '#':
            e.setAttribute('id', id[i].substring(1))
            break
          default:
            e.setAttribute('id', id[i])
        }
      }
      ;
    }

    for (var idx in attrs) {
      if ((idx === 'styles' || idx === 'style') && typeof attrs[idx] === 'object') {
        for (var prop in attrs[idx]) {
          e.style[prop] = attrs[idx][prop]
        }
      } else if (idx === 'html') {
        e.innerHTML = attrs[idx]
      } else {
        if (type == 'svg') {
          e.setAttributeNS(null, idx, attrs[idx])
        } else {
          e.setAttribute(idx, attrs[idx])
        }
      }
    }
    ;

    if (_classes.length) e.className = _classes.join(' ')
    if (c) c.appendChild(e)
    return e
  };
}
