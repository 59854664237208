import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import R from 'rellax';

export default class Scroll {
  constructor () {
      this.$hamburger = $('.js-trigger-nav-main');
      this.$header = $('.js-sec-header');
  }

  init () {
    this.scroll();
    this.goTo();
    this.menuMobile()
    this.paroller();
    AOS.init();
    let rellax = new R('.rellax');
  }

  paroller () {

  }

  scroll () {
      this.changeSizeHeader();
      $(document).on('scroll', (e) => {
          this.changeSizeHeader();
          var scrollPos = $(document).scrollTop();
          $('.js-sec-header ul a').each(function () {
              var currLink = $(this);

              if((currLink.attr('href').search("#") !== -1)){
                  var refElement = $(currLink.attr('href'));

                  if (refElement.position().top-60 <= scrollPos) {
                      $('.js-sec-header ul li a').removeClass("active");
                      currLink.addClass("active");
                  }
                  else{
                      currLink.removeClass("active");
                  }
              }
          });
      });
  }

  goTo () {
      this.changeSizeHeader();
      $('.js-scroll-to').on('click', function (event) {
          event.preventDefault();
          // this.changeSizeHeader();
          var target = $(this.getAttribute('href'));
          if (target.length) {
              event.preventDefault();

              $('html, body').stop().animate({
                  scrollTop: target.offset().top-50
              }, 800);
          }
      });
  }

    changeSizeHeader () {
        const scrollTop = $(window).scrollTop();
        let newHeight;

        newHeight = 100;

        if (scrollTop > newHeight) {
            this.$header.addClass('is-scrolled');
        } else {
            this.$header.removeClass('is-scrolled');
        }
    }
    menuMobile() {
        this.$hamburger.click(function() {
            $('.js-sec-header').toggleClass('active');
            $(this).toggleClass('active');
            $(this).next().toggleClass('active');
            return false;
        });
        $('.js-open-submenu').click(function () {

            if ($(this).closest('.c-nav-list').find('.js-submenu').hasClass('active')) {
                $(this).closest('.c-nav-list').find('.js-submenu').removeClass('active')
            } else {
                $(this).closest('.c-nav-list').find('.js-submenu').addClass('active')
            }

            if ($(this).hasClass('active')) {
                $(this).removeClass('active')
            } else {
                $(this).addClass('active')
            }

        })
    }
}


