import $ from 'jquery'
import {Loader, LoaderOptions} from 'google-maps';

export default class ShopsMapGoogle {

    constructor() {
        this.mapsContainer = $('.shops-map')[0]
    }

    onLocationFound(e) {
        var radius = e.accuracy / 2

        L.marker(e.latlng).addTo(this.map)
            .bindPopup("You are within " + radius + " meters from this point").openPopup()

        L.circle(e.latlng, radius).addTo(this.map)
    }

    init() {
        let _shopsData = window.shopsData
        if (!_shopsData) {
            console.warn("no shops Data")
            return
        }

        const markersGroupsStyles = [ { className: "marker-cluster-large", height: 40, width: 40 }, { className: "marker-cluster-large",height: 40, width: 40 }, {className: "marker-cluster-large", height: 40, width: 40 } ],
              mapStyle = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#cad2d3"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#999999"},{"lightness":17},{"weight":1.2}]}]

      function initialize() {
        const defaults = {
          center: new google.maps.LatLng(51.99526286348061, 22.692260742187504),
          styles: mapStyle,
          scrollWheelZoom: false,
          minZoom: 2,
          zoom: 7,
          gestureHandling: 'greedy',
          mapTypeControl: false,
          scaleControl: false,
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_TOP
          },
          streetViewControl: false,
          fullscreenControl: false
        }

        window.map = new google.maps.Map(document.getElementById('map'), defaults)

        var markers = []
        var infowindow = new google.maps.InfoWindow()

        for (var i = 0; i < _shopsData.cslav.sklepy.length; ++i) {
          const icon = {
            url: 'files/img/pin24.png',
            size: new google.maps.Size(28, 40),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(9, 21),
            scaledSize: new google.maps.Size(28, 40)
          }

          //console.log(_shopsData.cslav.sklepy[i])
          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(_shopsData.cslav.sklepy[i][3][0], _shopsData.cslav.sklepy[i][3][1]),
            map: map,
            icon: icon,
            md5: _shopsData.cslav.sklepy[i][3][0] + '-' + _shopsData.cslav.sklepy[i][3][1]
          })

          markers.push(marker)

          google.maps.event.addListener(marker, 'click', (function (marker, i) {
            return function () {
            
              infowindow.setContent('<div class="leaflet-popup  leaflet-zoom-animated"><div class="leaflet-popup-content-wrapper"><div class="leaflet-popup-content"><h2>'+ _shopsData.cslav.sklepy[i][1] +'</h2><p>'+ _shopsData.cslav.sklepy[i][2] +'</p></div></div><div class="leaflet-popup-tip-container"><div class="leaflet-popup-tip"></div></div></div>');
              infowindow.open(map, marker)
            }
          })(marker, i))
        }

        const _fitBoundRight = window.innerWidth > 1180 ? 28.791923 : 24.356928,
              _fitBoundTop = window.innerWidth > 1180 ? 54.957819 : 58.957819
              // [50.4079424,22.2880493],
              // [50.1633454,22.5316363]
        const southWest = new google.maps.LatLng(50.4079424,22.2880493),
              northEast = new google.maps.LatLng(50.1633454,22.5316363)

        const bounds = new google.maps.LatLngBounds(southWest,northEast)

        map.fitBounds(bounds)

        const mcOptions = { gridSize: 50, maxZoom: 11, styles: markersGroupsStyles ,clusterClass:"marker-cluster-large" },
            mc = new MarkerClusterer(map, markers, mcOptions)

        const listener = google.maps.event.addListener(map, "idle", function () {
          // map.setZoom(6)
          google.maps.event.removeListener(listener)
        })
      }

      window.addEventListener('load', initialize);

      this.searchFormInput = $('.l-shops__main input')[0];

      $('#map-search-form').submit(function (e) {
        e.preventDefault();
        google.maps.event.trigger(autocomplete, 'place_changed');
      }.bind(this));

      var autocomplete = new google.maps.places.Autocomplete(this.searchFormInput, {
        types: ['geocode'],
        componentRestrictions: {country: "pl"}
      });

      var autocomplete_detect = function () {
        var place = autocomplete.getPlace(),
            error = 0

        if (place.geometry) {
          if (place.geometry.viewport) {
            map.fitBounds(new google.maps.LatLngBounds(
                new google.maps.LatLng(place.geometry.viewport.getSouthWest().lat(), place.geometry.viewport.getSouthWest().lng()),
                new google.maps.LatLng(place.geometry.viewport.getNorthEast().lat(), place.geometry.viewport.getNorthEast().lng())
            ))
          } else if (place.geometry.location) {
            map.setViewByPoint(L.latLng(place.geometry.location.lat(), place.geometry.location.lng()), 15)
          } else {
            error = 1
          }
        } else {
          error = 2
        }
      }.bind(this)

      autocomplete.addListener('place_changed', autocomplete_detect)
    }

}
