import $ from 'jquery'
import Cookies from 'js-cookie'

export default class CookiesPopup {
    constructor () {
        this.cookieName = 'cookieMonitDisplayed'
        this.layer = $('.cookies-2-overlay')
        this.wrapper = $('.popup-cookies-2-wrapper')
    }

    init () {
        if (!Cookies.get(this.cookieName)) {
            this.showLayer()
            this.buttonClick()
        }
    }

    showLayer () {
        this.wrapper.addClass('is-active')
        this.layer.addClass('is-active')
    }

    hideLayer () {
        this.wrapper.fadeOut()
        this.layer.fadeOut()
    }

    buttonClick () {
        let $this = this

        $(".popup-cookies-2-buttons a").click(function (event) {
            event.preventDefault()

            $this.hideLayer()

            document.cookie = 'cookieMonitDisplayed=1; expires=Sat, 5 Dec 2021 12:00:00 UTC;domain=' + window.location.hostname

            var type = $(this).attr("data-id")

            if (type == "accept") {
                document.cookie = 'cookieAgreement=' + type + ';; expires=Sat, 5 Dec 2021 12:00:00 UTC;domain=' + window.location.hostname

                $('head').append('<!-- Google Tag Manager -->\n' +
                    '    <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':\n' +
                    '            new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],\n' +
                    '        j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\n' +
                    '        \'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);\n' +
                    '    })(window,document,\'script\',\'dataLayer\',\'GTM-WDX6QH\');</script>\n' +
                    '    <!-- End Google Tag Manager -->');

                $('body').append('<!-- Google Tag Manager (noscript) -->\n' +
                    '<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WDX6QH"\n' +
                    '                  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>\n' +
                    '<!-- End Google Tag Manager (noscript) -->');
            }
        })
    }

}